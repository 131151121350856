import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapVue3, BButton, BCard } from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App);
app.use(BootstrapVue3);
app.component('BButton', BButton);
app.component('BCard', BCard);
app.mount('#app');
