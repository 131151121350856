<template>
  <div class="app">
    <b-container>
      <b-row>
        <b-col>
          <h1>域名資料展示</h1>
        </b-col>
      </b-row>

      <!-- 展開所有用途 -->
      <b-collapse v-for="(value, key) in jsonData" :key="key" :visible="true">
        <b-card :header="key">
          <!-- 如果 item.域名列表 存在，顯示表格 -->
          <b-table v-if="value.length > 0" :items="value" :fields="fields">
            <template #cell(AWS源)="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(域名)="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(跳轉域名)="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(CDN)="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(備註)="data">
              <span>{{ data.value }}</span>
            </template>
            <template #cell(tag)="data">
              <span>{{ data.value }}</span>
            </template>

            <!-- 在這裡添加顏色變更邏輯 -->
            <template #row="row">
              <b-tr :style="row.item.備註.includes('ICP域名大陸加速') ? { backgroundColor: '#ADD8E6' } : {}">
                <td>{{ row.item.AWS源 }}</td>
                <td>{{ row.item.域名 }}</td>
                <td>{{ row.item.跳轉域名 }}</td>
                <td>{{ row.item.CDN }}</td>
                <td>{{ row.item.備註 }}</td>
                <td>{{ row.item.tag }}</td>
              </b-tr>
            </template>
          </b-table>

          <!-- 如果沒有資料，顯示提示 -->
          <div v-else>
            <p>沒有可展示的資料。</p>
          </div>
        </b-card>
      </b-collapse>
    </b-container>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  setup() {
    const jsonData = ref({});  // 用來存放 JSON 資料，以便每個類別單獨處理
    const fields = ref([
      { key: 'AWS源', label: 'AWS源' },
      { key: '域名', label: '域名' },
      { key: '跳轉域名', label: '跳轉域名' },
      { key: 'CDN', label: 'CDN' },
      { key: '備註', label: '備註' },
      { key: 'tag', label: 'Tag' },
    ]);

    // 加載 JSON 文件
    const loadJsonData = async () => {
      try {
        const response = await axios.get("/data.json");
        console.log("成功加載資料:", response.data); // 輸出加載的原始資料

        // 假設 JSON 的格式是 data.data，將其賦值給 jsonData
        jsonData.value = response.data.data;

        console.log("處理後的資料:", jsonData.value); // 輸出處理後的資料
      } catch (error) {
        console.error("加載 JSON 文件失敗:", error);
      }
    };

    // 當組件加載完成時執行加載資料的函數
    onMounted(() => {
      console.log("組件已加載，開始加載 JSON 資料...");
      loadJsonData();
    });

    return { jsonData, fields };
  }
};
</script>

<style scoped>
.app {
  font-family: Arial, sans-serif;
}

b-row, b-col {
  padding: 10px;
}

h1 {
  text-align: center;
}
</style>
